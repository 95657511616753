<template lang="html">
    <div class="MonitorSidebar">
      <div class="MonitorSidebar--Header">
        <p :class="{'selected':selectedTab == 0}" v-on:click="selectedTab = 0">{{ $t('watmonitor_questions') }}</p>
        <p :class="{'selected':selectedTab == 1}" v-on:click="selectedTab = 1">{{ $t('watmonitor_filters') }} <span v-show="$refs.Filters?.countSelected > 0">{{ $refs.Filters?.countSelected }}</span></p>
        <p :class="{'selected':selectedTab == 2}" v-on:click="selectedTab = 2">Split<span v-show="$refs.Split?.countSelected > 0">{{ $refs.Split?.countSelected }}</span></p>
        <div>
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 1.5V17.5M12 7.5L10 9.5L12 11.5M1 3.5C1 2.96957 1.21071 2.46086 1.58579 2.08579C1.96086 1.71071 2.46957 1.5 3 1.5H15C15.5304 1.5 16.0391 1.71071 16.4142 2.08579C16.7893 2.46086 17 2.96957 17 3.5V15.5C17 16.0304 16.7893 16.5391 16.4142 16.9142C16.0391 17.2893 15.5304 17.5 15 17.5H3C2.46957 17.5 1.96086 17.2893 1.58579 16.9142C1.21071 16.5391 1 16.0304 1 15.5V3.5Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
      </div>
      <div class="MonitorSidebar--Content" :class="{'tab2':selectedTab == 1,'tab3': selectedTab == 2}">
        <div class="MonitorSidebar--Split">
            <Split 
                ref="Split"
                :hideDashboardSocioDemographics="hideDashboardSocioDemographics"
                :globalDemographicFilters="globalDemographicFilters"
                :globalQuestionFilters="globalQuestionFilters"
                :globalFilters="globalFilters"
                v-on:update="updateFilter()"/>
        </div>
        <div class="MonitorSidebar--Filters">
            <Filters 
                ref="Filters"
                :hideDashboardSocioDemographics="hideDashboardSocioDemographics"
                :globalQuestionFilters="globalQuestionFilters"
                :globalDemographicFilters="globalDemographicFilters"
                :globalFilters="globalFilters"
                :questionsByTask="questionsByTask"
                :showSaveFilter="selectedTab == 1"
                :filterVariables="filterVariables"
                v-on:remove-variable="$emit('remove-variable', $event)"
                v-on:save-filter="$emit('save-filter')"
                v-on:updated-filter="updateFilter()"/>
        </div>
        <div class="MonitorSidebar--Questions">
            <CollapsableItem v-for="t in tasks" :num="t.taskNum" :text="t.name" v-on:collapse="openTask(t.taskNum)" :active="selectedTask == t.taskNum">
                <div v-for="(q, index) in t.questions" class="MonitorSidebar--Question" v-on:click="$emit('question-selected',{question:q,index: index})">
                    <QuestionIMG v-if="q.questionType != 'RANKING'" :name="t.taskNum == -1 && q.questionId == 1 ? 'PROVINCE' : q.taskNum == -1 && q.questionId == 2 ? 'AGE' : t.taskNum == -1 && q.questionId == 3 ? 'GENDER' : q.questionType" />
                    <QuestionIMG v-if="q.questionType == 'RANKING'" :name="q.questionType+'-STARS'" />
                    <p>
                        <span>{{ index }}.</span> {{ q.questionText }}
                    </p>
                </div>
            </CollapsableItem>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Vue from 'vue'
  import { mapGetters, mapMutations } from 'vuex'
  import Swal from 'sweetalert2'
  import QuestionIMG from '~/components/Question/QuestionIMG'
  import Filters from './Filters/Filters'
  import Split from './Filters/Split'
  import CollapsableItem from './Filters/CollapsableItem'
  export default {
    name: 'MonitorSidebar',
    components:{
        QuestionIMG,
        Filters,
        CollapsableItem,
        Split
    },
    props: {
        questionsByTask: Array,
        globalFilters: Array,
        globalDemographicFilters: Array,
        globalQuestionFilters: Array,
        filterVariables: Array,
        hideDashboardSocioDemographics:{
            type: Boolean,
            default: false
        }
    },
    computed:{
        tasks(){
            return this.questionsByTask
        }
    },
    data() {
      return {      
        selectedTab:0,
        selectedTask:0
      }
    },
    methods:{
        openTask(taskNum){
            if(taskNum == this.selectedTask) {
                this.selectedTask = 0
                return
            }
            this.selectedTask = taskNum
        },
        updateFilter(){
            this.$emit('updated-filter')
        }
    }
  }
  </script>
  <style lang="scss" scoped>
  .MonitorSidebar{
    border-right: 1px solid #EAEAEA;
    width: 350px;
    background: white;
    height: 100%;
    overflow: hidden;
    &--Header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #EAEAEA;
        text-transform: capitalize;
        p{
            font-family: Lato;
            font-size: 16px;
            font-weight: 600;
            padding: 24px 12px;
            position: relative;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 6px;
            &.selected::after{
                content:"";
                position: absolute;
                bottom: -4px;
                left: 0;
                background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
                height: 4px;
                width: 100%;
            }
            span{
                border-radius: 16px;
                background: #8134FF;
                color: white;
                font-family: Lato;
                font-size: 13px;
                text-align: center;
                padding: 2px 6px;
            }
        }
        div{
            display: flex;
            cursor: pointer;
            margin: 14px 13px 14px 0;
            padding: 10px 13px;
            border: 1px solid #C8C8C8;
            border-radius: 4px;
        }
    }
    &--Content{
        display: flex;
        width: 1050px;
        margin-left: -700px;
        overflow: hidden;
        transition: 0.5s;
        &.tab2{
            transform: translateX(350px);
        }
        &.tab3{
            transform: translateX(700px);

        }
    }
    &--Questions, &--Filters, &--Split{
        width: 350px;
        height: calc(100vh - 125px);
        overflow-y: scroll;
        &::-webkit-scrollbar-track{
            background-color: #e6e6e6;
        }
        &::-webkit-scrollbar {
            background: #8134ff;
            width: 3px;
        }
        &::-webkit-scrollbar-thumb{
            border-radius: 20px;
            background: #8134ff;
        }
    }
    &--Question{
        display: flex;
        align-items: flex-start;
        padding: 16px 0;
        gap: 8px;
        cursor: pointer;
        &:hover p{
            background-image: linear-gradient(90deg, #ff1564, #fd7328);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
        }
        p{
            transition: 0.2s;
            font-family: Lato;
            font-size: 14px;
            text-align: justify;
            color: #626262;
        }
        span{
            font-weight: 900;
            color: black;
        }
    }
  }
  </style>
    <style lang="scss">
        .QuestionIMG{
            img{
                width: 25px;
                height: 25px; 
            }
        }
    </style>