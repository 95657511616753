<template lang="html">
  <div class="ChartNew">
    <div class="ChartNew--Header">
      <h1><span v-if="question.taskNum > 0">{{ question.taskNum }}.{{ question.questionId }}</span> {{ question.questionText }}</h1>
      <div class="ChartNew--HeaderRight">          
        <i class="fas fa-photo-video" v-on:click="openMedia()" v-if="question.images || question.video"></i>
        <div class="ChartNew--Testers">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.25 14.75V13.25C1.25 12.4543 1.56607 11.6913 2.12868 11.1287C2.69129 10.5661 3.45435 10.25 4.25 10.25H7.25C8.04565 10.25 8.80871 10.5661 9.37132 11.1287C9.93393 11.6913 10.25 12.4543 10.25 13.25V14.75M11 1.34749C11.6453 1.51271 12.2173 1.88801 12.6257 2.41422C13.0342 2.94043 13.2559 3.58761 13.2559 4.25374C13.2559 4.91986 13.0342 5.56705 12.6257 6.09325C12.2173 6.61946 11.6453 6.99476 11 7.15999M14.75 14.75V13.25C14.7462 12.5879 14.5234 11.9456 14.1165 11.4233C13.7095 10.901 13.1411 10.528 12.5 10.3625M2.75 4.24998C2.75 5.04563 3.06607 5.8087 3.62868 6.37131C4.19129 6.93391 4.95435 7.24998 5.75 7.24998C6.54565 7.24998 7.30871 6.93391 7.87132 6.37131C8.43393 5.8087 8.75 5.04563 8.75 4.24998C8.75 3.45434 8.43393 2.69127 7.87132 2.12866C7.30871 1.56606 6.54565 1.24998 5.75 1.24998C4.95435 1.24998 4.19129 1.56606 3.62868 2.12866C3.06607 2.69127 2.75 3.45434 2.75 4.24998Z" stroke="#8134FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          {{ answersNum }} {{$t('watmonitor_respondents')}}
        </div>
      </div>
    </div>
    <div class="ChartNew--Content" :class="{'hasAverage':showAverage, 'hasTabs':showTabs, 'isEmpty':question.questionType == 'LABEL'}">
      <div class="ChartNew--Tabs" :class="{'selected':selectedMatrixValuesChart}" v-show="showValuesAverageTabs">
        <span></span>
        <button v-on:click="changeMatrixChart(true)">{{$t('watmonitor_values')}}</button>
        <button v-on:click="changeMatrixChart(false)">{{$t('watmonitor_mean')}}</button>
      </div>
      <div class="ChartNew--Tabs" :class="{'selected':showChartAnalisys}" v-show="showAnalysisTabs">
            <span></span>
            <button v-on:click="changeAnalysisChart(true)">{{$t('watmonitor_analisis_ia')}}</button>
            <button v-on:click="changeAnalysisChart(false)">{{$t('watmonitor_answers')}}</button>
      </div>
      <div class="ChartNew--Chart" v-if="showHeatMap">
        <div class="ChartNew--HeatmapSelection">
          <p v-for="(img,index) of question.images" :class="{'selected':index == currentHeatMap}" v-on:click="changeHeatMap(index)">{{$t('watmonitor_image')}} {{index + 1}}</p>
        </div>
        <HeatMapResults :ref="'HeatMapResults-'+_uid" :answersNum="1" :image="getHeatMapImageByIndex(currentHeatMap)" :heatMapAnswers="getHeatMapAnswersByIndex(currentHeatMap)" :heatmapPointDefinitions="getHeatMapPointsDefinitionByIndex(currentHeatMap)"/>
      </div>
      <div class="ChartNew--Chart" :id="'MonitorHighchart_'+_uid" :width="'100%'" v-show="showChart">

      </div>
      <div class="ChartNew--Chart" :id="'MonitorHighchart_'+_uid" :width="'100%'" v-if="showChartAnalisys">
        <div v-html="analysisData" class="ChartNew--AnalysisContent"></div>
      </div>
      <div class="ChartNew--Chart ChartNew--ChartList" :id="'MonitorHighchart_'+_uid" :width="'100%'" v-if="showList">
        <div v-show="!showChartAnalisys && question.questionType != 'IMAGE_URL'" class="ChartNew--SearchInput">
          <input v-if="question.questionType != 'MULTITEXT' && question.questionType != 'IMAGE_URL'" type="text" :placeholder="$t('watmonitor_find_answers')" v-model="question.textDimension.text" v-on:keyup="handlePageChange(pagination)">
          <input v-if="question.questionType == 'MULTITEXT'" type="text" :placeholder="$t('watmonitor_find_answers')" v-model="question.textDimension.multitext" v-on:keyup="handlePageChange(pagination)">
          <i class="fa fa-search"></i>
        </div>
        <div class="ChartNew--Scroll">
            <div class="" v-show="question.questionType != 'MULTITEXT'" v-for="(t,textIndex) in question.chart.texts">
              <div v-if="question.chart.total == 0">
                <p>{{ $t('watmonitor_no_data') }}</p>
              </div>
              <div v-for="t2 in t" v-if="t.length > 0">
                <p v-show="question.chart.yLabels.length>1" style="margin:10px 0;border-bottom: 1px solid lightgrey;"> - {{question.chart.yLabels[textIndex]}}</p>
                <p v-for="(t3,i) in t2" v-if="question.questionType != 'IMAGE_URL'"><span class="textAnswerIndex">{{getTextValueIndex(i)}}.</span>{{t3}}</p>
                <div v-if="question.questionType == 'IMAGE_URL'" class="ChartMonitor--ChartList--Viewer">
                  <MediaViewer :images="$filters.formatChartMedia(t2)"/>
                </div>
              </div>
            </div>
            <div class="" v-if="question.questionType == 'MULTITEXT'" v-for="t in question.chart.multitexts">
              <div v-if="question.chart.total == 0">
                <p>{{ $t('watmonitor_no_data') }}</p>
              </div>
              <div v-for="t2 in t" v-if="t.length > 0">
                <div v-for="t3 in t2">
                  <p v-for="(t4,i) in t3"><span class="textAnswerIndex">{{i+1}}.</span>{{t4}}</p>
                  <hr>
                </div>
              </div>
            </div>
          </div>
        <Pagination
          v-if="pageNum > 1"
          v-model="pagination"
          :records="this.question.chart.total"
          :per-page="10"
          @paginate="handlePageChange" />
      </div>
      <div class="ChartNew--Chart" :id="'MonitorHighchart_'+_uid" v-if="showWatUx">
        <WatUxMonitor :i18n="i18n" :uxData="question.chart" :testId="testId" :taskNum="question.taskNum"/>
      </div>
      <div class="ChartNew--DataBoxes" v-if="showDataBoxes">
        <div class="ChartNew--DataBox" v-if="showAverage">
          <span>{{$t('watmonitor_mean')}}</span>
         {{ question.chart?.average.toFixed(2) }}
        </div>
        <div class="ChartNew--DataBox" v-if="showAverage && question.chart?.columnAverages.length > 1" v-for="(ca, index) in question.chart?.columnAverages">
          <span>{{$t('watmonitor_mean')}} {{ question.chart?.columnLabels[index] }}</span>
         {{ typeof this.ca === 'number' ? ca.toFixed(2) : ca }}
        </div>
        <div class="ChartNew--DataBox" v-if="showMaxAndMin">
          <span>{{$t('watmonitor_minimum')}}</span>
          {{ question.chart?.testQuestion?.numMin }}
        </div>
        <div class="ChartNew--DataBox" v-if="showMaxAndMin">
          <span>{{$t('watmonitor_maximum')}}</span>
          {{ question.chart?.testQuestion?.numMax }}
        </div>
      </div>
    </div>

    <div class="ChartNew--Actions">
      <div>
        <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.8333 12C15.6069 12 16.3487 11.6927 16.8957 11.1457C17.4427 10.5987 17.75 9.85688 17.75 9.08333C17.75 8.30979 17.4427 7.56792 16.8957 7.02094C16.3487 6.47396 15.6069 6.16667 14.8333 6.16667H14C14.1216 5.62496 14.1234 5.06701 14.0054 4.52466C13.8874 3.98232 13.6519 3.4662 13.3123 3.00577C12.9727 2.54535 12.5356 2.14964 12.0261 1.84123C11.5165 1.53283 10.9445 1.31777 10.3426 1.20833C9.74069 1.0989 9.12074 1.09723 8.51814 1.20343C7.91553 1.30962 7.34206 1.5216 6.83048 1.82726C5.79729 2.44457 5.0789 3.40598 4.83332 4.5C3.94495 4.46442 3.07127 4.72533 2.36188 5.23808C1.65249 5.75083 1.15151 6.48352 0.944718 7.31069C0.737925 8.13786 0.838183 9.00807 1.22832 9.77232C1.61847 10.5366 2.27423 11.1473 3.08332 11.5M9 7.83334V15.3333M9 15.3333L6.5 12.8333M9 15.3333L11.5 12.8333" stroke="#1F1F1F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.99992 6.16666V6.17499M4.66659 6.16666V6.17499M11.3333 6.16666V6.17499M1.33325 14.5V3.66666C1.33325 3.00362 1.59664 2.36773 2.06549 1.89889C2.53433 1.43005 3.17021 1.16666 3.83325 1.16666H12.1666C12.8296 1.16666 13.4655 1.43005 13.9344 1.89889C14.4032 2.36773 14.6666 3.00362 14.6666 3.66666V8.66666C14.6666 9.3297 14.4032 9.96558 13.9344 10.4344C13.4655 10.9033 12.8296 11.1667 12.1666 11.1667H4.66659L1.33325 14.5Z" stroke="#1F1F1F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div>
        <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.24992 2.41667L12.5833 5.75M11.3333 12.8333H16.3333M1.33325 13.6667H4.66659L13.4166 4.91666C13.6355 4.69779 13.8091 4.43796 13.9275 4.15199C14.046 3.86602 14.1069 3.55953 14.1069 3.25C14.1069 2.94047 14.046 2.63397 13.9275 2.348C13.8091 2.06204 13.6355 1.8022 13.4166 1.58333C13.1977 1.36446 12.9379 1.19084 12.6519 1.07239C12.3659 0.953941 12.0594 0.892975 11.7499 0.892975C11.4404 0.892975 11.1339 0.953941 10.8479 1.07239C10.562 1.19084 10.3021 1.36446 10.0833 1.58333L1.33325 10.3333V13.6667Z" stroke="#1F1F1F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 9L3.58333 13.5833M9 9V1.5M9 9H16.5M9 1.5C9.98491 1.5 10.9602 1.69399 11.8701 2.0709C12.7801 2.44781 13.6069 3.00026 14.3033 3.6967C14.9997 4.39314 15.5522 5.21993 15.9291 6.12987C16.306 7.03982 16.5 8.01509 16.5 9M9 1.5C8.01509 1.5 7.03982 1.69399 6.12987 2.0709C5.21993 2.44781 4.39314 3.00026 3.6967 3.6967C3.00026 4.39314 2.44781 5.21993 2.0709 6.12987C1.69399 7.03982 1.5 8.01509 1.5 9C1.5 9.98491 1.69399 10.9602 2.0709 11.8701C2.44781 12.7801 3.00026 13.6069 3.6967 14.3033C4.39314 14.9997 5.21993 15.5522 6.12987 15.9291C7.03982 16.306 8.01509 16.5 9 16.5C9.98491 16.5 10.9602 16.306 11.8701 15.9291C12.7801 15.5522 13.6069 14.9997 14.3033 14.3033C14.9997 13.6069 15.5522 12.7801 15.9291 11.8701C16.306 10.9602 16.5 9.98491 16.5 9" stroke="#1F1F1F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, inject, onMounted } from 'vue';
import { removeHTML, isMatrixChart, isMatrixRadioButtonChart, isDividePointsChart, isSortingChart, isNumberChart } from '~/utils/watmonitor/'
import Highcharts from "highcharts";
import Pagination from 'v-pagination-3';
import WatUxMonitor from './WatUxMonitor'
import MediaViewer from '~/components/MediaViewer'
import HeatMapResults from '~/components/HeatMap/HeatMapResults'


export default {
  name: 'ChartNew',
  components:{
    Pagination,
    WatUxMonitor,
    MediaViewer,
    HeatMapResults
  },
  props: {
    question: Object,
    testId:[Number,String],
    i18n:Object,
    personalization:Object
  },
  data() {
    return{
      selectedMatrixValuesChart: true,
      showChartAnalisys: false,
      isFirstChartGeneration: true,
      showChart: false,
      showList: false,
      showWatUx: false,
      showHeatMap: false,
      pageNum:0,
      pagination:1,
      currentHeatMap:0,
      _uid:getCurrentInstance().uid,
      chartConfig:{
        credits: {
            enabled: false
        },
        legend:{
          enabled: true,
          maxHeight: 100,
          reversed: false
        },
        colors:["#8134ff","#FF1564","#FD7328","#361466","#895AD2","#96C800","#FD4D40","#f9c39f","#ede8f2","#669d98","#a81187","#8aefbb"],
        chart: {
            type: 'bar',
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        tooltip: {
            backgroundColor:'rgb(255,255,255)',
            headerFormat: '<span style="font-size:11px;color:{point.color}">{series.name}</span><br>',
            pointFormat: '<b>'+this.$t('watmonitor_sample')+': </b>{point.custom.total} <br> <b>'+this.$t('watmonitor_number')+': </b>{point.custom.number} <br> <b>'+this.$t('watmonitor_percentage')+': </b>{point.custom.percentY:.2f}%<br/>'
        },
        plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0,
              groupPadding: 0.05,
              dataLabels: {
                enabled: true,
                format: '{point.y:.1f}%',
              }
            },
            bar:{
              borderWidth: 0,
              pointPadding: 0.2,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
                  format: '{point.y:.1f}%',
              }
          },
          series:{
          }
        },
        series: [],
        animation: {
          enabled: true,
          duration: 1000,
          easing: 'linear'
        }
      },
      chart:{}
    }
  },
  computed:{
    answersNum(){
      if(this.question.chart == undefined) return 0 
      return this.question.chart.answersNum || this.question.chart.total
    },
    showAverage(){
      return this.question.questionType == 'RANKING' || this.question.questionType == 'RATING_FACES'
    },
    showDataBoxes(){
      return this.showAverage || this.showMaxAndMin
    },
    showMaxAndMin(){
      return isNumberChart(this.question)
    },
    showTabs(){
      return this.showValuesAverageTabs || this.showAnalysisTabs
    },
    showValuesAverageTabs(){
      return (this.question.questionType == 'MATRIX' && this.question.matrixType != 'RADIO_BUTTONS') || isSortingChart(this.question)
    },
    showAnalysisTabs(){
      const chart = this.question.chart
      if(chart == undefined) return false
      const analysis = chart.analysis
      return analysis != undefined && analysis != null && analysis.length > 0
    },
    analysisData(){
      if(this.question.chart.analysis == null || this.question.chart.analysis == undefined) return ""
      const text = this.question.chart.analysis.replace(/\n/g, "<br>")
      return text
    }
  },
  methods:{
    generate(isChangeChart){
      const questionType = this.question.questionType
      if(questionType == 'IMAGE_URL' || questionType == 'DATE' || questionType == 'TEXT' || questionType == 'MULTITEXT' || questionType == 'TEXTAREA'){
        this.generateList()
        return
      }
      if(questionType == "WATUX"){
        this.showWatUx = true
        return
      }
      if(questionType == "HEATMAP") {
        this.showHeatMap = true
        return
      }
      if(questionType == 'LABEL' || questionType == 'MULTINUMBER') return
      this.showChart = true
      this.chartConfig.colors = this.personalization && this.personalization.colors && this.personalization.colors.length > 0 ? this.personalization.colors : this.chartConfig.colors
      this.chartConfig.xAxis = this.generateXAxis(isChangeChart)
      this.chartConfig.yAxis.title.text = this.$t(`watmonitor_chart_axis_title.${this.question.yAxisTitle}`)
      if((isSortingChart(this.question) && this.selectedMatrixValuesChart) || (isMatrixChart(this.question) && (isMatrixRadioButtonChart(this.question) || this.selectedMatrixValuesChart))) this.generateStackedBar()
      
      if (this.isFirstChartGeneration) this.initializeChart()
      else this.updateChart()

      this.isFirstChartGeneration = false
    },
    generateList(){
      this.showList = true
      this.pageNum = Math.floor(this.question.chart.total / 10)
      let needsOnePageMore = (this.question.chart.total % 10)>0
      if (needsOnePageMore){
          this.pageNum++;
      }
    },
    initializeChart(){
      this.chartConfig.chart.type = this.getChartType()
      this.chartConfig.series = this.generateSeries()
      this.chart = Highcharts.chart('MonitorHighchart_'+this._uid,this.chartConfig);
    },
    updateChart(){
      this.removeSeries()
      this.chartConfig.series = this.generateSeries()
      for(let serie of this.chartConfig.series){
        this.chart.addSeries({name:serie.name, data:serie.data}, false)
      }
      this.chart.update(this.chartConfig, false);
      this.chart.redraw(true);
    },
    generateLabels(){
      const type = this.question.questionType
      if(type == 'RATING_FACES') return this.generateRatingFacesLabels()
      if(type == 'IMAGE_CHOICE' || type == 'MULTIPLEIMAGE_CHOICE') return this.generateImageLabels()
      return this.generateBasicLabels()
    },
    generateImageLabels(){
      const vm = this
      const labels = {
        formatter: function() {
          const values = vm.question.chart.testQuestion.values
          const valueId = this.value.replace("Imagen num.","")
          const value = values.filter(v => v.id == valueId)[0]
          const image = value.image
          return '<img src="' + image + '" style="vertical-align: middle; width: 42px; height: 42px"/>';
        },
        useHTML: true
      } 
      return labels
    },
    generateRatingFacesLabels(){
      const labels = {
        formatter: function() {
            return '<img src="'+require('~/assets/img/execution/rating_face' + this.value + '.png')+'" style="vertical-align: middle; width: 32px; height: 32px"/>';
        },
        useHTML: true
      } 
      return labels
    },
    generateBasicLabels(){
      const labels = {
        formatter: function () {
            return '<span title="' + this.value + '">' + this.value + '</span>';
        }
      }
      return labels
    },
    generateStackedBar(){
      this.chartConfig.plotOptions.series.stacking = 'normal'
    },
    getChartType(){
      return this.question.questionType != 'RANKING' && this.question.questionType != 'RATING_FACES' ? 'bar' : 'column'
    },
    generateXAxis(isChangeChart){
      const optionLabels = this.question.chart.optionLabels
      const rowLabels = this.question.chart.rowLabels.map((label)=> {
        if (label === "Mujer" || label === "Hombre") {
          return this.$t(`watmonitor_chart_row_labes.${label}`)
        }else{
          return label
        }
      })
      const currentLabels = this.chartConfig.xAxis.labels
      if(isMatrixChart(this.question) && (isMatrixRadioButtonChart(this.question) || this.question.chart.columnLabels.length > 0)){
        return isChangeChart ? { categories: optionLabels, label: currentLabels } : this.generateXaxisMatrix(optionLabels, this.question.chart.columnLabels)
      }
      else if(isSortingChart(this.question) && (!this.selectedMatrixValuesChart || this.question.chart.columnLabels.length > 0)){
        return this.generateXaxisMatrix(rowLabels, this.question.chart.columnLabels)
      }
      else if(isDividePointsChart(this.question)){
        return this.generateXaxisMatrix(rowLabels, this.question.chart.columnLabels)
      }
      let categories = isMatrixChart(this.question) ? optionLabels : rowLabels
      if(categories != null && categories != undefined) categories = categories.map(c => removeHTML(c));
      if(isNumberChart(this.question) && this.question.taskNum > 0) categories = ['Media']

      const labels = this.generateLabels()
      return { categories: categories, labels: labels }
    },
    removeSeries(){
      for(var i = this.chart.series.length - 1; i > -1; i--) {
        this.chart.series[i].remove(false);
      }
    },
    generateSeries(){
      const { columnLabels, rowLabels, values, columnAverages, optionIndexes } = this.question.chart;

      let series = []
      if(isMatrixRadioButtonChart(this.question)){
        this.chartConfig.legend.reversed = true
        series = this.generateMatrixSeries(rowLabels, values)
      }
      else if(isMatrixChart(this.question)){
        if(this.selectedMatrixValuesChart){
          this.chartConfig.legend.reversed = true
          this.chartConfig.yAxis.min = 0
          this.chartConfig.yAxis.max = null 
          series = this.generateMatrixSeries(rowLabels, values)
        }
        else {
          this.chartConfig.yAxis.min = this.question.chart.testQuestion.rankingMin
          this.chartConfig.yAxis.max = this.question.chart.testQuestion.rankingMax 
          series = this.generateAverageSeries(this.question.chart.optionColumnAverage)
        }
      }
      else if(isSortingChart(this.question)){
        if(this.selectedMatrixValuesChart){
          this.chartConfig.legend.reversed = true
          let optionTotals = optionIndexes.map(num => num + 1);
          series = this.generateMatrixSeries(optionTotals, values)
        }
        else {
          series = this.generateAverageSeries(this.question.chart.optionColumnEstimatedAverage)
        }
      }
      else if(isDividePointsChart(this.question)){
        this.chartConfig.yAxis.min = 0
        this.chartConfig.yAxis.max = this.question.chart.testQuestion.pointsNum
        series = this.generateAverageSeries(this.question.chart.optionColumnAverage)
      }
      else if(isNumberChart(this.question) && this.question.taskNum > 0){
        series = this.generateAverageNumberSeries(columnLabels, columnAverages)
      }
      else if(this.question.questionId < 0 && this.question.taskNum > 0){
        series = this.generateAverageNumberSeries(columnLabels, columnAverages)
      }
      else{
        series = this.generateBasicSeries(columnLabels, values)
      }

      this.chartConfig.chart.height = this.calculateChartHeight(series)

      return series
    },
    calculateChartHeight(series){
      let height = 420
      const seriesLength = series.map(s => s.data.length);
      const seriesSize = isMatrixChart(this.question) || isSortingChart(this.question) ? seriesLength[0] * 75 : seriesLength.reduce((accumulator, currentValue) => accumulator + currentValue, 0) * 50;
      
      if(seriesSize > height){
        if(isMatrixChart(this.question)){
          this.chartConfig.plotOptions.series.pointWidth = 35
        }
        else {
          this.chartConfig.plotOptions.series.pointWidth = 20
          this.chartConfig.plotOptions.series.groupPadding = 0.1
        }
      }
      return Math.max(seriesSize, 420);
    },
    generateAverageSeries(averages){
      let vm = this
      this.chartConfig.tooltip.pointFormat = '<b>: </b>{point.custom.total} <br> <b>Número: </b>{point.custom.number} <br>'
      this.chartConfig.plotOptions.bar.dataLabels.format = '{point.y:.1f}'
      let series = [{name:this.$t('watmonitor_analisis_ia'), data:[], showInLegend: false}]
      for(let i of Object.keys(averages)){
        for(let i2 of Object.keys(averages[i])){
          series[0].data.push({
            y:averages[i][i2],
            custom :{
              total:vm.question.chart.total.number,
              number: averages[i][i2].toFixed(2)
            }
          })
        }
      }
      return series
    },
    generateAverageNumberSeries(columnLabels, columnAverages){
      let vm = this
      this.chartConfig.tooltip.pointFormat = '<b>Muestra: </b>{point.custom.total} <br> <b>Número: </b>{point.custom.number} <br>'
      this.chartConfig.plotOptions.bar.dataLabels.format = '{point.y:.1f}'
      let series = []
      for(let i in columnAverages){
        series.push({
          name: columnAverages.length > 0 ? columnLabels[i] : this.$t('watmonitor_mean'), data:[columnAverages[i]], showInLegend: columnAverages.length > 0
        })
      }
      return series
    },
    generateBasicSeries(columnLabels, values){
      let series = []
      for(let i in values){
        for(let i2 in values[i][0]){
          if(series[i2] == undefined) {
            series[i2] = {name:columnLabels[i2], data:[], showInLegend: columnLabels.length > 0}
          }
          const tooltipData = {
            percentY:values[i][0][i2].percentY,
            number:values[i][0][i2].number,
            total: this.question.chart.total.number
          }
          series[i2].data.push({y:values[i][0][i2].percentY,custom:tooltipData})
        }
      }
      return series
    },
    generateMatrixSeries(columnLabels, values){
      let series = []
      for(let i in values){
        for(let i2 in values[i]){
          for(let i3 in values[i][i2]){
            if(series[i] == undefined){
              series[i] = {name: columnLabels[i], data:[]}
            }

            const tooltipData = {
              percentY:this.question.questionType == 'MATRIX' && this.question.matrixType == 'RADIO_BUTTONS' ? values[i][i2][i3].percentZ : values[i][i2][i3].percentY,
              number:values[i][i2][i3].number,
              total: this.question.chart.total.number
            }
            series[i].data.push({y:this.question.questionType == 'MATRIX' && this.question.matrixType == 'RADIO_BUTTONS' ? values[i][i2][i3].percentZ : values[i][i2][i3].percentY,custom:tooltipData})
          }
        }
      }
      return series.reverse()
    },
    generateXaxisMatrix(optionLabels, columnLabels){
      let columns = []
      let optionsSplit = []
      optionLabels.forEach((rl) => {
        columnLabels.forEach(cl => {
          optionsSplit.push(rl)
          columns.push(cl)
        });
      });
      this.question.chart.optionLabels = optionsSplit.length == 0 ? optionLabels : optionsSplit      

      const labels = {
          formatter: function () {
              const index = this.pos;
              const value = this.value.length > 150 ? this.value.substring(0, 150) + '...' : this.value;
              const val = `<span title="${this.value}">${value} - ${columns[index]}</span>`;
              return optionsSplit.length > 0 ? val : `<span title="${this.value}">${this.value}</span>`;
          }
      };

      this.question.chart.optionLabels = this.question.chart.optionLabels.map(op => removeHTML(op));

      return { categories: this.question.chart.optionLabels, labels: labels}
    },
    changeMatrixChart(value){
      if(value == this.selectedMatrixValuesChart) return
      this.selectedMatrixValuesChart = value
      this.generate(true)
    },
    changeAnalysisChart(value){
      if(value){
        this.showList = false
        this.showChartAnalisys = true
      }
      else{
        this.showList = true
        this.showChartAnalisys = false
      }
      this.showChartAnalisys = value
    },
    handlePageChange(page){
      this.$emit('change-page', { page: page-1 })
    },
    getTextValueIndex(index){
      if(this.pagination == 1) return index + 1
      return (this.pagination - 1) * 10 + (index + 1)
    },
    openMedia(){
      this.$emit('open-media', {images: this.question.images, video: this.question.video})
    },
    changeHeatMap(index){
      this.currentHeatMap = index
      this.$refs['HeatMapResults-'+this._uid].reloadHeatMap()
    },
    getHeatMapImageByIndex(index){
      return this.question.images[index]
    },
    getHeatMapAnswersByIndex(index){
      let pointsByImageIndex = _.filter(this.question.chart.heatmapPoints, function (p) { return p.imageIndex == index });
      let data = {
        hmvaluesByType:{
          RED: _.filter(pointsByImageIndex, function (p) { return p.type == "RED" }),
          GREEN:_.filter(pointsByImageIndex, function (p) { return p.type == "GREEN" }),
        },
        hmvaluesByPoint:{}
      }
      let pointsByIndex = {}
      for(let point of pointsByImageIndex){
        if (pointsByIndex[point.identifier] == undefined) pointsByIndex[point.identifier] = []
        pointsByIndex[point.identifier].push(point)
      }
      let pointsByIndexOrdered = {}
      let pointsIndex = 0
      for (var [key, value] of Object.entries(pointsByIndex)) {
          pointsByIndexOrdered[pointsIndex] = value
          pointsIndex++
      }
      data.hmvaluesByPoint = pointsByIndexOrdered
      return data
    },
    getHeatMapPointsDefinitionByIndex(index){
      let data = _.filter(this.question.heatmapPointDefinitions, function (p) { return p.imageIndex == index });
      return data
    },
  }
}
</script>

<style scoped lang="scss">
.ChartNew{
  border: 1px solid #EAEAEA;
  background: white;
  border-radius: 8px;
  display: grid;
  overflow: hidden;
  grid-template-areas:
    'header actions'
    'content actions';
    grid-template-columns: auto 52px;
  &--Header{
    grid-area: header;
    border-bottom: 1px solid #EAEAEA;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    h1{
      font-family: Bariol;
      font-size: 18px;
      font-weight: 700;
      text-align: justify;
    }
  }
  &--HeaderRight{
    display: flex;
    align-items: center;
    gap: 15px;
    i{
      cursor: pointer;
    }
  }
  &--Content{
    width: 100%;
    grid-area: content;
    display: flex;
    min-height: 420px;
    padding: 24px;
    &.hasTabs{
      flex-direction: column;
    }
    &.isEmpty{
      min-height: 0px;
    }
  }
  &--Actions{
    grid-area: actions;
    border-left: 1px solid #EAEAEA;
    padding: 16px 0;
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    div{
      cursor: pointer;
      width: 100%;
      &:hover svg path{
        transition: 0.2s;
        stroke: #ff1564;
      }
    }
  }
  &--DataBox{
    width: 100%;
    height: fit-content;
    border: 1px solid #EAEAEA;
    border-radius: 4px;
    padding:6px 0;
    display: flex;
    flex-direction: column;
    font-family: Lato;
    span{
      border-bottom: 1px solid #EAEAEA;
      padding-bottom: 6px;
      margin-bottom: 6px;
      font-weight: bold;
    }
  }
  &--DataBoxes{
    width: 100px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &--Tabs{
    display: flex;
    position: relative;
    width: fit-content;
    height: fit-content;
    border-radius: 4px;
    border: 1px solid #EAEAEA;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;    
    gap: 15px;
    &.selected{
      span{
        left: 6px;
      }
      button:first-of-type{
        color:white;
      }
      button:nth-of-type(2){
        color:black;
      }
    }
    button{
      z-index: 1;
      font-size:14px;
      border: none;
      width: 50%;
      background: transparent;
      cursor: pointer;
      color: black;
      transition:0.5s;
      &:nth-of-type(2){
        color:white;
      }
    }
    span{
      position: absolute;
      width: calc(50% - 6px);
      height: calc(100% - 9px);
      left: 50%;
      background: linear-gradient(90deg,#ff1564,#fd7328);
      border-radius: 4px;
      transition: 0.2s;
    }
  }
  &--Testers{
    display: flex;
    width: fit-content;
    gap:6px;
    padding:6px 12px;
    border-radius: 4px;
    border: 1px solid #EAEAEA;
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    text-wrap: nowrap;
  }
  &--Chart{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &--ChartList{
    height: 420px;
    p{
      font-size: 16px;
      text-align: justify;
      margin-bottom: 5px;
      span{
        background: linear-gradient(90deg, #ff1564, #fd7328);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: Roboto Condensed;
        font-size: 18px;
      }
    }
  }
  &--SearchInput{
    position: relative;
    margin: 0 0 10px 0;
    input{
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      width: 100%;
      font-family: Roboto Condensed;
      font-weight: normal;
      font-size: 17px;
      letter-spacing: 0.01em;
      color: #969696;
      padding: 6px;
      outline: none;
      &::placeholder{
        opacity: 0.6;
      }
    }
    i{
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 18px;
      color:#969696;
    }
  }
  &--Scroll{
    //max-height: 247px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 5px;
    &::-webkit-scrollbar-track{
      background-color: #e6e6e6;
    }
    &::-webkit-scrollbar {
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      width: 3px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 20px;
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    }
  }
  &--AnalysisContent{
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #f8f8f8;
    padding: 10px;
    border-radius: 6px;
    text-align: justify;
    &::-webkit-scrollbar-track{
      background-color: #e6e6e6;
    }
    &::-webkit-scrollbar {
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      width: 3px;
      height: 3px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 20px;
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    }
  }
  &--HeatmapSelection{
    display: flex;
    p{
      background: #96C800;
      box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.14);
      border-radius: 5px;
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      color: #FFFFFF;
      width: fit-content;
      padding: 5px 10px;
      margin-right: 10px;
      cursor: pointer;
      text-transform: uppercase;
      outline: none;
      border:none;
      position: relative;
      &.selected{
        border: 1px solid #96C800;
        background: white;
        color: #96C800;
      }
      &:before {
        content:"";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 0px;
        height: 0px;
        background: rgba(255, 255, 255, 0.2);
        transition: width 0.5s, height 0.5s;
      }
      &:hover:before {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>